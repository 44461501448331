<template>
	<div :id="uppyId" :endpoint="endpoint">
		<DashboardModal :uppy="uppy" :open="open" :autoRefresh="autoRefresh" :isCreateADebt="isCreateADebt"> </DashboardModal>
		<div :class="DashboardModal"></div>
	</div>
</template>

<script>
	import { computed } from 'vue'
	import { DashboardModal } from '../../../modules/customVue3SupportBackup/src/index'
	import XHRUpload from '@uppy/xhr-upload'
	import { useStore } from 'vuex'
	import axios from 'axios'

	// import '@uppy/core/dist/style.css'

	import '@uppy/dashboard/dist/style.css'

	import Uppy from '@uppy/core'

	export default {
		props: {
			open: Boolean,
			endpoint: {
				type: String,
				default: '/',
			},
			uppyId: {
				type: String,
				required: true,
			},
			autoRefresh: {
				type: Boolean,
				default: true,
			},
			isCreateADebt: {
				type: Boolean,
				default: true,
				required: false,
			},
		},
		setup(props) {
			const store = useStore()
			const uppy = computed(() => {
				return new Uppy({
					restrictions: {
						maxNumberOfFiles: 1,
						allowedFileTypes: ['.pdf', '.docx', 'image/*'],
					},
				}).use(XHRUpload, {
					endpoint: store.state.devUrl + props.endpoint,
					formData: true,
					method: 'patch',
					headers: {
						authorization: `Bearer ${localStorage.token}`,
					},
				})
			})

			let url = window.location.pathname
			let id = url.split('/')[url.split('/').length - 1]

			uppy.value.on('dashboard:modal-closed', () => {
				if (props.isCreateADebt) {
					store.commit('toggleModal', 1)
				}
				if (props.uppyId === 'four') {
					store.commit('toggleModal', 4)
				}
			})

			uppy.value.on('upload-success', (file, response) => {
				if (props.isCreateADebt) {
					store.dispatch('toggleModal', 1)
					store.dispatch('fileIds', response.body)
					store.dispatch('uploadSuccess')
					document.body.classList.remove('uppy-Dashboard-isFixed')
				}

				if (!props.isCreateADebt) {
					store.dispatch('debtProcess/getAllStagesData', response.body.value[0])
				}

				if (props.uppyId === 'four') {
					axios({
						method: 'patch',
						url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: {
							debtId: id,
							stageId: 3,
							sectionId: 2,
							progress: 3,
							isCompleted: false,
						},
					}).catch(err => {
						console.log(err)
					})
				}

				if (!props.isCreateADebt) {
					props.autoRefresh ? location.reload() : uppy.value.close()
				}
				// do something with file and response
			})

			return {
				DashboardModal,
				uppy,
				store,
			}
		},
	}
</script>

<style lang="scss">
	@import url('https://cdnjs.cloudflare.com/ajax/libs/uppy/1.26.0/uppy.min.css');
	[dir='ltr'] .uppy-Dashboard-close {
		right: 15px;
	}
	a.uppy-Dashboard-poweredBy {
		display: none;
	}
	.uppy-Dashboard-close {
		color: $darkBlue;
	}
	@media only screen and (min-width: 820px) {
		.uppy-Dashboard-close {
			top: 10px;
		}
	}
	@media only screen and (max-width: 820px) {
		.uppy-Dashboard-inner {
			height: 80vh;
		}
	}
</style>
